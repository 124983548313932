
function Footer() {
    return (
      <div className="footer">
        <p className="footer-text">
          Rechtsanwälte Erdmann und Rattay, Am Markt 7, 25436 Uetersen<br />
          Berufsrechtliche Regelungen und Informationen gemäß der Dienstleistungs-Informationspflichen-Verordnung (DL-InfoV) 
        </p>
      </div>
    );
  }
  
  export default Footer;
  