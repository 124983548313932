import foto from '../images/tschuess.jpg';

function Kontakt() {
  return (
    <div className="content">
      <h1>Kontakt</h1>
      {/* <img src={foto} alt="Team" /> */}
      <p>Am Markt 7, 1.OG<br />
      25436 Uetersen<br /><br />

      Telefon: <a href="tel:+49412244032">04122/44032</a><br />
      Telefax: 04122/2731</p>
      <p>Unser Büro ist wie folgt geöffnet:</p>{/* ist montags, dienstags und donnerstags von 8:30 bis 13:00 Uhr sowie von 14:00 bis 17:00 Uhr und am Freitag von 8.30 bis 13.00 Uhr geöffnet.</p> */}
      <p>
        <ul>
          <li>Montag: 8:30 - 13:00 und 14:00 - 17:00</li>
          <li>Dienstag: 8:30 - 13:00 und 14:00 - 17:00</li>
          <li>Mittwoch: 8:30 - 13:00 </li>
          <li>Donnerstag: 8:30 - 13:00 und 14:00 - 17:00</li>
          <li>Freitag: 8:30 - 15:00</li>
        </ul>
      </p>

      <p>Nach vorheriger Terminvereinbarung stehen wir Ihnen natürlich auch außerhalb der genannten Öffnungszeiten zur Verfügung.</p>
      <p>Parkplätze stehen auf dem gegenüberliegenden Marktplatz zur Verfügung.</p>
      <p>Unsere Kanzlei befindet sich im Zentrum der ca. 25 km nordwestlich von Hamburg gelegenen Rosenstadt Uetersen. Die örtlichen Amtsgerichte befinden sich in Pinneberg und Elmshorn, ca. 10 km vom Sitz der Kanzlei entfernt. In Elmshorn befindet sich zudem das für einen weiten Teil der Westküste Schleswig-Holsteins zuständige Arbeitsgericht.</p>
      <p>Selbstverständlich sind wir für unsere Mandenten aber auch weit darüber hinaus in anderen Gerichtsbezirken tätig, überwiegend in den Landgerichtsbezirken Itzehoe und Hamburg, Kiel und Lübeck, sowie am Oberlandesgericht, Oberverwaltungsgericht und Landessozialgericht Schleswig.</p>
      {/* <p>Wir sind überwiegend im Landgerichtsbezirk Itzehoe (mit den Gerichten AG Itzehoe, AG Meldorf dem LG Itzehoe) auch im Landgerichtsbezirk Hamburg (mit sämtlichen Stadtteilgerichten und dem Landgericht Hamburg) tätig.</p> */}
      <p>Alle Anwälte unserer Kanzlei sind auftrittsberechtigt vor allen Land- und Oberlandesgerichten in der Bundesrepublik Deutschland, was eine qualifizierte Betreuung auch in der Berufungsinstanz ermöglicht. Dadurch sind wir in der Lage Verfahren auch in anderen Bundesländern zu betreuen.</p>
    </div>
  );
}

export default Kontakt;
