import titelBild from '../images/titel.gif'

function Home() {
  return (
    <div>
      <img style={{paddingTop: "150px"}} src={titelBild} alt="Titel" />
    </div>
  );
}

export default Home;
