import foto from '../images/bruecke.jpg';

function Impressum() {
  return (
    <div className="content">
      <h1>Impressum</h1>
      {/* <img src={foto} alt="Anwälte" /> */}
      <p>
        Rechtsanwalt Thomas Erdmann<br />
        Rechtsanwältin Christiane Rattay
      </p>
      <p>
        <h4>Kontakt</h4>
        Am Markt 7 (5-11), 1.OG<br />
        25436 Uetersen<br />
        Telefon: <a href='tel:+49412244032'>04122 / 44032 / -33</a><br />
        Telefax: 04122 / 2731<br />
        e-Mail: info@kanzlei-uetersen.de<br />
      </p>
      <p>
        <h4>Rechtsform:</h4>
        Die Kanzlei Erdmann und Rattay ist eine Gesellschaft bürgerlichen Rechts.
      </p>
      <p>
        Steuernummer 1322004952, FA Elmshorn
      </p>
      <p>
        <h4>Berufsbezeichnung und zuständige Kammer</h4>
        Die Rechtsanwälte der Kanzlei sind nach dem Recht der Bundesrepublik Deutschland zugelassen und Mitglieder der Rechtsanwaltskammer Schleswig-Holstein
      </p>
      <p>
        Schleswig-Holsteinische Rechtsanwaltskammer<br />
        Gottorfstraße 13<br />
        24837 Schleswig<br />
        Telefon: 04621/93910<br />
        Telefax: 04621/939126<br />
      </p>
      <p>
        <h4>Berufshaftpflichtversicherung</h4>
        ERGO Versicherung AG<br />
        40477 Düsseldorf<br />
        Die Rechtsanwälte der Kanzlei sind bei der ERGO Versicherung AG haftpflichtversichert.<br />
      </p>
      <p>
        <h4>Berufsrechtliche Regelungen</h4>
        Es gelten die folgenden berufsrechtlichen Regelungen
        <ul>
          <li>Bundesrechtsanwaltsordnung (BRAO)</li>
          <li>Rechtsanwaltsvergütungsgesetz (RVG)</li>
          <li>Berufsordnung der Rechtsanwälte (BORA)</li>
          <li>Fachanwaltsordnung (FAO)</li>
        </ul>
      </p>
      <p>
        Die berufsrechtlichen Regelungen können über die <a href='http://brak.de'>Homepage der Bundesrechtsanwaltskammer</a> unter dem Link "Berufsrecht" eingesehen und abgerufen werden.
      </p>
    </div>
  );
}

export default Impressum;
