import foto from '../images/kanzlei.jpg';
import '../App.css';

function Kanzlei() {
  return (
    <div className="content">
      <h1>Kanzlei</h1>
      {/* <img src={foto} alt="Kanzlei" /> */}
      <p>Unsere Kanzlei wurde 1992 von Rechtsanwalt Thomas Erdmann in der Rosenstadt Uetersen gegründet.</p>
      <p>Als gleichberechtigte Partner wurde 1995 Rechtsanwältin Christiane Rattay aufgenommen.</p>
      <p>Beide Sozien waren bereits vor dem Zusammenschluss etliche Jahre als Rechtsanwälte tätig.</p>
      <p>Zu unseren Mandanten zählen Freiberufler, mittelständische Unternehmen sowie Privatpersonen, die eine qualitativ hochwertige Beratung benötigen. Wir legen besonderen Wert auf eine professionelle, vertrauensvolle und vor allem ergebnisorientierte Vertretung unserer Mandantschaft.</p>
      <p>Dieser Aufgabe werden wir uns im Rahmen unserer bewährten, teamorientierten Zusammenarbeit mit großer Freude und ebenso großem Engagement stellen.</p>
    </div>
  );
}

export default Kanzlei;
