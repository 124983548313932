import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Nav from './Nav';
import Footer from './Footer';

import Home from './pages/Home';
import Kontakt from './pages/Kontakt';
import Themen from './pages/Themen';
import Kanzlei from './pages/Kanzlei';
import Impressum from './pages/Impressum';

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <div className="main-area">
        <div className="main-area-content">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/kanzlei" exact component={Kanzlei} />
          <Route path="/themen" exact component={Themen} />
          <Route path="/kontakt" exact component={Kontakt} />
          <Route path="/impressum" exact component={Impressum} />
        </Switch>
        </div>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
