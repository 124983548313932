import './App.css';
import { Link } from 'react-router-dom';

function Nav() {

    return (
        <nav>
            <h3><Link style={{fontSize: 32}} className='nav-link' to="/">KANZLEI AM MARKT</Link></h3>

            <ul className="nav-links">
                <li><div className="nav-box"><Link className='nav-link' style={{fontSize: 20}} to="/kanzlei">Kanzlei</Link></div></li>
                <li><div className="nav-box"><Link className='nav-link' style={{fontSize: 20}} to="/themen">Tätigkeitsgebiete</Link></div></li>
                <li><div className="nav-box"><Link className='nav-link' style={{fontSize: 20}} to="/kontakt">Kontakt</Link></div></li>
                <li><div className="nav-box"><Link className='nav-link' style={{fontSize: 20}} to="/impressum">Impressum</Link></div></li>
            </ul>
        </nav>
    );
}

export default Nav;
