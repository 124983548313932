import foto from '../images/mitHund.jpg';

function Themen() {
  return (
    <div className="content">
      <h1>Tätigkeitsgebiete</h1>
      {/* <img src={foto} alt="Anwälte" /> */}
      <p>Unsere Kanzlei ist vornehmlich zivilrechtlich orientiert. Schwerpunkte unserer zivilrechtlichen Tätigkeit liegen im Bereich des Familien- und Erbrechts, des Arbeitsrechts, des Verkehrsrechts, des gewerblichen und privaten Mietrechts, des Schadensersatzrecht und im Bereich von Vertragsgestaltungen. Gleichermaßen vertreten wir Mandanten im Rahmen von sozialrechtlichen Angelegenheiten und in verwaltungsrechtlichen Bereichen. Die Bearbeitung von Aufträgen für den Forderungseinzug privater Natur sowie auch für Firmen und Geschäftsleute gehört zu unseren täglichen Aufgaben.</p>
      <p>Wir unterstützen Sie auch bei der Vorbereitung und strategisch sinnvollen Durchführung einer eventuell notwendigen Strafverteidigung, angefangen vom Straßenverkehrsverstoß und anderen Ordnungswidrigkeiten bis hin zu Strafprozessen, die eine Betreuung über längere Dauer erfordern.</p>
      <p>Sie werden in uns stets geduldige Zuhörer und entschlossene Vertreter Ihrer Interessen finden. Und sollte wirklich einmal ein "exotisches" Rechtsproblem auftauchen, empfehlen wir Ihnen einen Spezialisten außerhalb unserer Kanzlei, bei dem Sie ebenso wie bei uns Rat und Hilfe erhalten.</p>
    </div>
  );
}

export default Themen;
